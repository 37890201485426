<template>
<!-- eslint-disable -->
    <div class="modal-mask" v-if="loading === true">
        <div class="loading">
            <vue-loading type="spiningDubbles" color="#81D4FA" :size="{ width: '100px', height: '100px'}"></vue-loading>
        </div>
    </div>
</template>

<script>
/*eslint-disable */
import { VueLoading } from 'vue-loading-template'
export default {
    name: 'Loading',
    components: {
        VueLoading
    },
    computed: {
        loading() {
            return  this.$store.getters.getLoading
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-mask {
    z-index:999;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>