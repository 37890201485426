<template>
<!-- eslint-disable -->
    <div>
		<v-app-bar
			app
			:color="this.color"
			height="60"
			dark
		>
			<div class="ml-2">
				<v-icon @click="home()" size=35>mdi-ferris-wheel</v-icon>
			</div>
			<v-spacer></v-spacer>
			<v-icon v-if="batchFlg" size=25 color=red style="position: absolute; top: 0px; right: 43px; z-index: 99;">mdi-alert-circle</v-icon>
			<img style="max-width: 45px; border-radius: 50%; z-index: 999; pointer-events: none;" :src="'icon/'+this.img" alt="userImg">
			<v-select :items="items" v-model="this.page" @input="router" style="max-width: 10px; position: absolute; top: 0; right: 35px;"></v-select>
		</v-app-bar>
		<v-main  style="overflow-x: hidden !important;">
            <v-container fluid>
				<Loading/>
                <router-view @getBatch="getBatch"/>
            </v-container>
        </v-main>
		<Footer />
    </div>
</template>

<script>
/*eslint-disable */
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
// import ErrorDialog from '@/components/ErrorDialog.vue'
export default {
    name: 'Common',
	components: { Footer, Loading },
    data() {
        return {
			batchFlg: false,
			userMenuFlg: false,
			items: [
				'めも帳',
				'takeno残高確認',
				'保持アイテムリスト',
				'ユーザー情報編集',			
			],
		}
    },
    methods: {
		getBatch(batchFlg){
			this.batchFlg = batchFlg
		},
		home(){
			// this.page = ''
			this.$store.dispatch('setPage', '')
			if (this.$route.path !== '/common/home') this.$router.push({name: 'Home'})
		},
		router(name) {
			let path = ''

			// this.page = name
			this.$store.dispatch('setPage', name)
			switch(this.page) {
				case 'めも帳':
					path = 'Memo'
				break
				case 'takeno残高確認': 
					path = 'Takeno'
				break
				case '保持アイテムリスト': 
					path = 'ItemList'
				break
				case 'ユーザー情報編集': 
					path = 'Setting'
				break
			}
			this.$router.push({name: path})
		}
    },
	created() {
		if (!this.$route.params.userData) this.$router.push({name: 'Login'})
		this.getBatch()
	},
	computed: {
		name() {
            return this.$store.getters.loginInfo.name
		},
        color() {
            return this.$store.getters.loginInfo.color
        },
		img() {
			return this.$store.getters.loginInfo.img
		},
		page() {
			return this.$store.getters.page
		},
    },
}
</script>
<style scoped>
.v-select__slot {
    display: none !important;
}
</style>